import React, {useState, useEffect} from 'react'
import { motion } from "framer-motion";
import { toast } from "react-hot-toast";
import { Tooltip as Mensaje } from "react-tooltip";
import Swal from 'sweetalert2'
import { eliminarDeportistaGeneral, registrarDeportistaGeneral, reiniciarRankingGeneral, traerClubesAutorizados, traerDeportistaParaFederar, traerRanking, traerRankingSinAcomodar } from '../controllers/configuracionFirebase';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { arreglarTiempoEstandar } from '../controllers/pdf'; 
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from 'primereact/checkbox';

const Afiliados = () => {

  const [ edadRegistrada, setEdadRegistrada ] = useState();
  const [ deportistaEncontrado, setDeportistaEncontrado ] = useState();
  const [ ranking, setRanking ] = useState([]);
  const [ rankingIndividual, setRankingIndividual ] = useState([]);
  const [ resultadoBusqueda, setResultadoBusqueda ] = useState([]);
  const [ tiempos, setTiempos ] = useState([]);
  const [ dialogFederacion, setDialogFederacion ] = useState(false);
  const [ dialogEliminarDeportista, setDialogEliminarDeportista ] = useState(false);
  const [ expandedRows, setExpandedRows ] = useState(null);
  const [ globalFilter, setGlobalFilter ] = useState(null);
  const [ pruebaElegida, setPruebaElegida ] = useState('');
  const [ edadElegida, setEdadElegida ] = useState([]);
  const [ opcionesElegidos, setOpcionesElegidos ] = useState([]);
  const [ generoElegido, setGeneroElegido ] = useState('');
  const [ departamentoElegido, setDepartamentoElegido ] = useState('');
  const [ fechaInicialElegida, setFechaInicialElegida ] = useState('');
  const [ fechaFinElegida, setFechaFinElegida ] = useState('');
  const departamentosColombia = [
    "Amazonas",
    "Antioquia",
    "Arauca",
    "Atlántico",
    "Bolívar",
    "Boyacá",
    "Caldas",
    "Caquetá",
    "Casanare",
    "Cauca",
    "Cesar",
    "Chocó",
    "Córdoba",
    "Cundinamarca",
    "Guainía",
    "Guaviare",
    "Huila",
    "La Guajira",
    "Magdalena",
    "Meta",
    "Nariño",
    "Norte de Santander",
    "Putumayo",
    "Quindío",
    "Risaralda",
    "San Andrés y Providencia",
    "Santander",
    "Sucre",
    "Tolima",
    "Valle del Cauca",
    "Vaupés",
    "Vichada",
  ];

  function sacarPruebas(){
    const auxPruebas = [
      '50 Libre',
      '100 Libre',
      '200 Libre',
      '400 Libre',
      '800 Libre',
      '1500 Libre',

      '50 Espalda',
      '100 Espalda',
      '200 Espalda',

      '50 Pecho',
      '100 Pecho',
      '200 Pecho',

      '50 Mariposa',
      '100 Mariposa',
      '200 Mariposa',

      '200 Combinado',
      '400 Combinado',
    ];

    return auxPruebas;
  }

  //Ranking
  const edadesRanking = [
    {edad: '10'},
    {edad: '11'},
    {edad: '12'},
    {edad: '13'},
    {edad: '14'},
    {edad: '15'},
    {edad: '16'},
    {edad: '17'},
    {edad: '18'},
    {edad: '19 y Mas'},
  ]

  function nombreNadadorMayuscula(nombre){
    const nombreMinuscula = nombre.toLowerCase();
    const nombrecompleto = nombreMinuscula.split(' ');
    let nombreMayuscula = '';
    for (let i = 0; i < nombrecompleto.length; i++) {
      nombreMayuscula += nombrecompleto[i].charAt(0).toUpperCase() + nombrecompleto[i].slice(1) + " ";
    }
    return nombreMayuscula.trim();
  }

  function onSubmit(e){
    e.preventDefault();
    const nombre = e.target.nombre.value;
    const identificacion = e.target.identificacion.value;
    const fechaNacimiento = e.target.fechaNacimiento.value;
    const edad = e.target.edad.value;
    const genero = e.target.genero.value;
    const objClub = JSON.parse(e.target.nombreclub.value);

    const auxFederado = {
      club: deportistaEncontrado ? deportistaEncontrado.club : objClub.nombre,
      nombre: deportistaEncontrado ? nombreNadadorMayuscula(deportistaEncontrado.nombre) : nombreNadadorMayuscula(nombre),
      identificacion: deportistaEncontrado ? deportistaEncontrado.identificacion : identificacion,
      fechaNacimiento: deportistaEncontrado ? deportistaEncontrado.fechaNacimiento: fechaNacimiento,
      edad: deportistaEncontrado ? deportistaEncontrado.edad : edad,
      genero: deportistaEncontrado ? deportistaEncontrado.genero : genero,
      departamento: deportistaEncontrado ? deportistaEncontrado.departamento : objClub.departamento,
      federacion: true,
      pruebas: []
    }

    registrarDeportistaGeneral(auxFederado.identificacion, auxFederado);
    traerRanking(setRanking);
    setEdadRegistrada();
    setDeportistaEncontrado();
    e.target.reset();
  }

  function buscarDeportista(){
    traerDeportistaParaFederar(document.getElementById('deportCC').value, setDeportistaEncontrado, calcularEdad, setEdadRegistrada);
  }

  function eliminarFederado(e){
    e.preventDefault();
    const cc = e.target.identificacion.value;
    setDialogEliminarDeportista(false);
    try {
      const alerta = Swal.mixin({
        toast: true,
        showCancelButton: true
      })
      alerta.fire({
        html: `<div class="w-100 position-relative">
        <img style="width: 100px; position: absolute; top: 0; right: 0; z-index: -10" />
        <p style="z-index: 10; font-family: Poppins" class="mb-0">¿Esta seguro de eliminar al </br> deportista Federado?
        </div>`,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: 'rgb(18, 53, 248)',
        padding: 8,
        cancelButtonText: 'Cancelar',
      }).then((respuesta) =>{
        if(respuesta.isConfirmed){
          eliminarDeportistaGeneral(cc);
          traerRanking(setRanking);
        }
      })
    } catch (err) {
      toast.error('UPS! ocurrio un error, intentalo de nuevo.');
    }
  }

  function reiniciarRanking(){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 33px; right: 0; z-index: -10" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">¿Esta seguro de reiniciar el ranking? Esto eliminara todas las </br> pruebas registradas en el </br> Ranking,
      lo que reiniciara </br> los records.
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        reiniciarRankingGeneral();
        traerRanking(setRanking);
      }
    })
  }

  const header = (
    <div className="d-flex justify-content-start align-items-center">
      <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
      </span>
    </div>
  );

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={data.pruebas} emptyMessage="No hay tiempos registrados.">
        <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
        <Column field="prueba" header="Prueba" style={{ width: '110px'}}></Column>
        <Column field="record" header="Tiempo" body={(prue)=>{return arreglarTiempoEstandar(prue.record)}} style={{ width: '80px'}}></Column>
        <Column field="fecha" header="Fecha" style={{ width: '80px'}}></Column>
      </DataTable>
    );
  };

  const agregarInscrito = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button icon="pi pi-plus" className='me-1' severity="success" raised tooltip='Federar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogFederacion(true)}/>
        <Button icon="pi pi-refresh" className='me-1' severity="primary" raised tooltip='Reiniciar Ranking' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => reiniciarRanking()}/>
        <Button icon="pi pi-times" severity="danger" raised tooltip='Eliminar Federado' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogEliminarDeportista(true)}/>
      </div>
    );
  };

  const footerGroupDeportista = (
    <ColumnGroup>
        <Row>
            <Column footer="Total:" colSpan={1} footerStyle={{ textAlign: 'right' }} />
            <Column footer={rankingIndividual.length} />
        </Row>
    </ColumnGroup>
  );

  function calcularEdad(fecha) {
    var hoy = new Date();
    var fechaNacimiento = fecha;
    var cumple = fechaNacimiento.split('-');
    var cumpleanosAno = parseInt(cumple[0]);
    var edad = hoy.getFullYear() - cumpleanosAno;

    return edad;
  }

  function onChangeEdad(){
    const fecha = document.getElementById('fechaNacimiento').value;
    if(fecha !== ''){
      setEdadRegistrada(calcularEdad(fecha));
    }
  }

  function sacarEdadesElegidas(e){
    let auxEdadElegidas = [...edadesRanking];
    let auxOpcionesElegidas = [...opcionesElegidos];

    if(e.checked){
      auxOpcionesElegidas.push(e.value);
    }else{
      auxEdadElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setEdadElegida(auxEdadElegidas);
    setOpcionesElegidos(auxOpcionesElegidas);
  }

  function reducirPalabraDepartamento(palabra){
    const arrPalabra = palabra.split('');
    if(arrPalabra[0] && arrPalabra[1] && arrPalabra[2] && arrPalabra[3] && arrPalabra[0]){
      return `${arrPalabra[0] + arrPalabra[1] + arrPalabra[2] + arrPalabra[3] + arrPalabra[4]}`;
    }

    if(arrPalabra[0] && arrPalabra[1] && arrPalabra[2] && arrPalabra[3]){
      return `${arrPalabra[0] + arrPalabra[1] + arrPalabra[2] + arrPalabra[3]}`;
    }
  }
  
  useEffect(() => {
    function rangoFechaEventos(fechaRecord){
      const auxfechaRecord = new Date(fechaRecord);
      const auxfechaInicial = new Date(fechaInicialElegida);
      const auxfechaFin = new Date(fechaFinElegida);
      let fechaEnElrango = false;
      if(auxfechaRecord >= auxfechaInicial && auxfechaRecord <= auxfechaFin){
        fechaEnElrango = true;
      }
      return fechaEnElrango;
    }

    const obtenerRecordMenor = (deportista, prueba) => {
      const recordDeportista = deportista.pruebas.find(
        (pruebaDeportista) => pruebaDeportista.prueba === prueba && rangoFechaEventos(pruebaDeportista.fecha)
      );
    
      if (recordDeportista) {
        const record = recordDeportista.record.split(":").map(Number);
        const totalMilisegundos = record[0] * 60 * 1000 + record[1] * 1000 + record[2];
    
        if (totalMilisegundos === 0) {
          return Infinity;
        }
    
        return totalMilisegundos;
      }
    
      return Infinity; // Si no hay registro, lo consideramos infinito para que aparezca al final de la lista
    };

    function sacarGrupo(prueba, edad, genero, departamento){
      const auxNad = [];
      if(edad.length !== 0){
        edad.forEach((ed)=>{
          auxNad.push(...ranking[ed]);
        })
      }else{
        edadElegida.forEach((ed)=>{
          auxNad.push(...ranking[ed.edad]);
        })
      }
      if(auxNad.length !== 0){
        const auxRanking = [...auxNad];
        let auxDeportistas = [];
        if(departamento === 'todos'){
          auxDeportistas = auxRanking.filter((auxRan)=>{return auxRan.genero === genero && auxRan.pruebas.some(prue => rangoFechaEventos(prue.fecha) && prue.prueba === pruebaElegida)});
        }else{
          auxDeportistas = auxRanking.filter((auxRan)=>{return auxRan.genero === genero && auxRan.departamento === departamento && auxRan.pruebas.some(prue => rangoFechaEventos(prue.fecha) && prue.prueba === pruebaElegida)});
        }
    
        const auxDeportPruebas = [];
        const tiempo = [];
    
        auxDeportistas.sort((a, b) => {
          const recordA = obtenerRecordMenor(a, prueba);
          const recordB = obtenerRecordMenor(b, prueba);
          return recordA - recordB;
        });
    
        auxDeportistas.forEach((auxDepor)=>{
          auxDepor.pruebas.forEach((prue)=>{
            if(prue.prueba === prueba && prue.record !== '0:0:0'){
              tiempo.push(prue);
              auxDeportPruebas.push(auxDepor);
            }
          })
        })
    
        setResultadoBusqueda(auxDeportPruebas);
        setTiempos(tiempo);
      }
    }

    sacarGrupo(pruebaElegida.trim(), opcionesElegidos, generoElegido, departamentoElegido);
  }, [opcionesElegidos, generoElegido, departamentoElegido, pruebaElegida, ranking, fechaFinElegida, fechaInicialElegida])

  useEffect(() => {
    traerRanking(setRanking, setRankingIndividual);
  }, [])

  useEffect(() =>{
    if(!dialogFederacion){
      setEdadRegistrada();
      setDeportistaEncontrado();
    }
  },[dialogFederacion])
  
  return (
    <div className='bg-light'>
      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          <h2>RANKING PROWEBSPORTS</h2>
        </div>
        <div className='d-flex flex-wrap justify-content-center'>
          <div className="form-floating me-1">
            <input className='form-control mb-2' type="date" name="fechaInicial" id='fechaInicial' onChange={(ev) => setFechaInicialElegida(ev.target.value)}/>
            <label htmlFor="fechaInicial">Fecha Inicial</label>
          </div> 
          <div className="form-floating me-1">
            <input className='form-control mb-2' type="date" name="fechaFin" id='fechaFin' onChange={(ev) => setFechaFinElegida(ev.target.value)}/>
            <label htmlFor="fechaFin">Fecha Fin</label>
          </div> 
          <div className="form-floating me-1">
            <select name="prueba" id="prueba" className='form-select' style={{maxWidth: '250px'}} onChange={(ev) => setPruebaElegida(ev.target.value)}>
              <option value="">Elige la prueba</option>
              {sacarPruebas().map((prue, indexPrue)=>{
                return <option key={indexPrue} value={prue}>{prue}</option>
              })}
            </select>
            <label htmlFor="prueba">Prueba</label>
          </div>
          <div className="form-floating me-1">
            <select className='form-select' name="genero" id="genero" style={{maxWidth: '250px'}} onChange={(ev) => setGeneroElegido(ev.target.value)}>
              <option value="">Elige</option>
              <option value="Femenino">Femenino</option>
              <option value="Masculino">Masculino</option>
            </select>
            <label htmlFor="genero">Género</label>
          </div>
          <div className="form-floating">
            <select className='form-select' name="departamento" id="departamento" style={{maxWidth: '250px'}} onChange={(ev) => setDepartamentoElegido(ev.target.value)}>
              <option value="">Elige el departamento</option>
              <option value="todos">Todos</option>
              {departamentosColombia.map((depar, indexDepar)=>{
                return <option key={indexDepar} value={depar}>{depar}</option>
              })}
            </select>
            <label htmlFor="departamento">Departamento</label>
          </div>
        </div>
        <div>
        <Accordion className='m-auto mt-2' activeIndex={0} style={{maxWidth: '850px'}}>
          <AccordionTab header="Categorías">
            <div className="d-flex flex-wrap justify-content-evenly">
              {edadesRanking.map((ed, indexEdad)=>{
                return <div key={indexEdad} className="d-flex align-items-center" style={{width: '180px'}}>
                <Checkbox inputId={ed.edad} name="pizza" value={ed.edad} onChange={sacarEdadesElegidas} checked={opcionesElegidos.includes(ed.edad)}/>
                <label htmlFor={ed.edad} className="ml-2">{ed.edad + ' Años'}</label>
              </div>
              })}
            </div>
          </AccordionTab>
        </Accordion>
        </div>
        {resultadoBusqueda.length !== 0 && tiempos.length !== 0 ?(
          <>
            <DataTable value={resultadoBusqueda} selectionMode="single" dragSelection size='small' stripedRows paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No hay resultados aún." 
            tableStyle={{ minWidth: '50rem', margin: 'auto'}}>
              <Column header="Pto" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
              <Column header="Equipo" body={(rank) => `${(rank.club) + '/' + (reducirPalabraDepartamento(rank.departamento))}`} style={{ width: '180px' }}></Column>
              <Column field="nombre" header="Deportista" style={{ width: '200px', fontWeight: 'bold' }}></Column>
              <Column header="Puntos" body={(_, indexRank) => `${tiempos[indexRank.rowIndex].pa}`} style={{ width: '50px'}}></Column>
              <Column header="Fecha Evento" body={(_, indexRank) => `${tiempos[indexRank.rowIndex].fecha}`} style={{ width: '130px'}}></Column>
              <Column header="Tiempo" body={(_, indexRank) => `${arreglarTiempoEstandar(tiempos[indexRank.rowIndex].record)}`} style={{ width: '70px'}}></Column>
            </DataTable>
          </>
          ):(
            <>
            <Toolbar left={agregarInscrito}></Toolbar>
            <DataTable value={rankingIndividual} selectionMode="single" dragSelection expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} header={header} globalFilter={globalFilter} size='small' stripedRows paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50, 100]} emptyMessage="No hay resultados aún." 
            footerColumnGroup={footerGroupDeportista} tableStyle={{ minWidth: '60rem', margin: 'auto'}}>
              <Column expander style={{ width: '30px' }} />
              <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
              <Column header="Equipo" body={(rank) => `${(rank.club) + '/' + (reducirPalabraDepartamento(rank.departamento))}`} style={{ width: '180px' }}></Column>
              <Column field="nombre" sortable header="Nombre" style={{ width: '180px', fontWeight: 'bold' }}></Column>
              <Column field="identificacion" sortable header="Identificación" style={{ width: '70px'}}></Column>
              <Column field="fechaNacimiento" sortable header="Fecha Nto" style={{ width: '110px'}}></Column>
              <Column field="edad" sortable header="Edad" style={{ width: '50px'}}></Column>
              <Column sortable header="Federación" body={(rank) => <Tag icon={true ? 'pi pi-check-circle' : 'pi pi-times-circle'} value={true ? 'Afiliado/a' : 'No Afiliado/a'} className={true ? 'bg-success' : 'bg-danger'}></Tag>} style={{ width: '50px'}}></Column>
            </DataTable>
          </>
        )}
      </motion.div>
      <Dialog visible={dialogFederacion} style={{ width: '46rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header='FEDERACIÓN' modal className="p-fluid" onHide={() => setDialogFederacion(false)}>
        <div className="field">
          <form onSubmit={onSubmit} className='text-center'>
            <div className="form-floating">
              <input type="number" className='form-control mb-1' id='deportCC' placeholder='Buscar deportista por identificación'/>
              <label htmlFor="deportCC">Buscar deportista por identificación</label>
            </div>
            <Button type='button' icon='pi pi-search' label='Buscar' className='btn btn-secondary' style={{borderRadius: '10px'}} onClick={() => buscarDeportista()}></Button>
            <hr />
            <input className='form-control' type="hidden" name="edad" id='edad' value={edadRegistrada || ''}/>
            <label htmlFor="edad" className='w-100 mb-2'>Edad Federado: {edadRegistrada || '0'} Años</label>
            <div className='d-flex flex-wrap justify-content-between'>
              {deportistaEncontrado ? (
                <>
                  <div className="form-floating">
                    <input type="hidden"  name='nombreclub' value={JSON.stringify('')}/>
                    <input type="text" className='form-control' id='auxnombreclub' disabled={true} defaultValue={deportistaEncontrado.club}/>
                    <label htmlFor="auxnombreclub">Equipo</label>
                  </div>
                </>
              ):(
                <div className="form-floating mb-2">
                  <select name="nombreclub" id="nombreclub" className='form-select' aria-label="Default select example" required onChange={onChangeEdad}>
                    <option value="">Elige</option>
                  </select>
                  <label htmlFor="nombreclub">Club</label>
                </div>
              )}
              <div className="form-floating">
                <input className='form-control mb-2' type="text" maxLength={25} name="nombre" id='nombre' required defaultValue={deportistaEncontrado ? deportistaEncontrado.nombre : ''} />
                <label htmlFor="nombre">Nombre Completo</label>
              </div> 
              <div className="form-floating">
                <input className='form-control mb-2' type="number" disabled={deportistaEncontrado} name="identificacion" id='identificacion' required defaultValue={deportistaEncontrado ? deportistaEncontrado.identificacion : ''}/>
                <label htmlFor="identificacion">Identificación</label>
              </div>
              <div className="form-floating">
                <input className='form-control mb-2' type="date" name="fechaNacimiento" id='fechaNacimiento' required defaultValue={deportistaEncontrado ? deportistaEncontrado.fechaNacimiento : ''} onChange={onChangeEdad}/>
                <label htmlFor="fechaNacimiento">Fecha De Nacimiento</label>
              </div>
              <div className="form-floating mb-2">
                <select name="genero" id="genero" className='form-select' defaultValue={deportistaEncontrado ? deportistaEncontrado.genero : ''} aria-label="Default select example" required onChange={onChangeEdad}>
                  <option value="">Elige</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Masculino">Masculino</option>
                </select>
                <label htmlFor="genero">Género</label>
              </div>
            </div>
            <Mensaje
              anchorId={`nombre`}
              place="bottom"
              content={'Solo se admiten 25 caracteres incluyendo el espacio.'}
              className="bg-primary shadow"
              style={{ zIndex: 1}}
            />
            <Button type='submit' icon='pi pi-user-plus' label='Federar' className='btn btn-success mt-2' style={{borderRadius: '10px'}}></Button>
          </form>
          <hr />
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <b>Importante:</b> Para asegurar el mejor funcionamiento al calcular la edad, evita hacer copiar y pegar o escribir directamente en el campo de la fecha de nacimiento. En su lugar, te recomendamos buscar directamente la fecha de nacimiento del deportista en el calendario para asegurarte de que la información sea precisa y se ingrese correctamente.
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      </Dialog>
      <Mensaje
        anchorId={`nombre`}
        place="bottom"
        content={'Solo se admiten 25 caracteres incluyendo el espacio.'}
        className="bg-primary shadow"
        style={{ zIndex: 1}}
      />
      <Dialog visible={dialogEliminarDeportista} style={{ width: '46rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header='ELIMINAR FEDERADO' modal className="p-fluid" onHide={() => setDialogEliminarDeportista(false)}>
        <form onSubmit={eliminarFederado}>
          <div className="form-floating">
          <input type="number" className='form-control' name='identificacion' id='identificacion' placeholder='Elimina al deportista con su indentificación' required/>
            <label htmlFor="identificacion">Elimina al deportista con su identificación</label>
          </div>
          <Button type='submit' icon='pi pi-user-minus' label='Eliminar Federado' className='btn btn-success mt-2' style={{borderRadius: '10px'}}></Button>
        </form>
      </Dialog>
    </div>
  )
}

export default Afiliados;